/* General Header Styling */
.header {
  background-color: black;
  color: white;
  padding: 40px 20px;
  position: relative; /* Relative positioning to avoid any fixed behavior */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensures the header stays on top */
}

.header-image-container {
  margin-top: 20px; /* Adds space between header and banner */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.banner {
  z-index: 5; /* Ensures the banner is below the header */
}

.header-image {
  width: 100%;
  height: auto;
}

/* Card Styling */
.card-header {
  background-color: white;
  border-radius: 20px;
  width: 80%;
  padding: 20px;
  position: absolute;
  top: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo and Company Name */
.logo-name {
  display: flex;
  align-items: center;
}

.logo {
  width: 170px;
  height: 70px;
  margin-right: 5px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: black;
  text-decoration: none;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-links a i {
  font-size: 1rem;
}

.down-arrow {
  font-size: 0.8rem;
}

.book-now {
  padding: 10px 10px;
  background-color: #00008B;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2rem;

}

/* Dashboard Button for Mobile */
.dashboard-btn {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Social Media Icons Above the Card */
.social-icons {
  position: absolute;
  top: 10px;
  left: 130px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.social-icons i {
  font-size: 1.5rem;
  color: #25d366;
}
.social-left{
  margin-left:20px;
}
.social-right {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 280px;
}

.social-right i {
  font-size: 1.5rem;
  color: blue;
}

@media (max-width: 768px) {
  .card-header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 15px;
    z-index: 1; 
    margin-bottom: 15px; 
  }

  .logo {
    width: 80px;
    height: 35px;
  }

  .dashboard-btn {
    display: block;
    margin-left: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem; 

  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 40px;
    gap: 15px;
    border-radius: 5px;
    position: absolute; 
    top: 80%; 
    left: 2px; 
    width: 98.5%; 
    z-index: 2; 
  }
  .nav-links.open {
    display: flex;
    margin-top: 0; 
  }

  .social-right {
    top: 40px;
  }

  .social-icons {
    margin-top: 20px;
  }
}





























/* .header {
    background-color: black;
    color: white;
    padding: 40px 20px; 
    position: relative;
    text-align: center;
    height: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-header {
    background-color: white;
    border-radius: 15px;
    width: 80%;
    padding: 20px;
    position: absolute;
    top: 30px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
\  .logo-name {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 40px;
    height: 30px;
    margin-right: 50px;
  }
  
  .company-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    color: black;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .down-arrow {
    font-size: 0.8rem;
  }
  
  .book-now {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .social-icons {
    position: absolute;
    top: 10px;
    left: 160px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top:5px;
  }
  
  .social-icons i {
    font-size: 1.5rem;
    color: #25d366; 
  }
  
  .social-right {
    display: flex;
    gap: 15px;
    position: absolute;
    right: 330px;
    
  }
  .social-right i {
    font-size: 1.5rem;
    color: blue; 
  }
  
  @media (max-width: 768px) {
    .card-header {
      flex-direction: column;
      padding: 15px;
    }
  
    .nav-links {
      flex-direction: column;
      gap: 10px;
    }
  
    .social-right {
      top: 40px;
    }
  }
   */