.ro-system-container {
  text-align: center;
  /* padding: 90px; */
  width: 100%;
  height: 100%;
  margin-top:150px;
}

.title {
  font-size: 44px;
  margin-bottom: 20px;
}

.slider-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.image-container {
  display: flex;
  flex-direction: column; 
}
.small-image1 {
  width: 200px; 
  height: 200px; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden;
}

.small-image1 img {
  width: auto;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.small-image1, .large-image {
  text-align: center;
  overflow: visible;
}
.small-image1 {
  width: 200px;
  height: 200px; 
  background-color: black; 
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden; 
}

.small-image img {
  width: auto;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

/* .small-image img {
  width: auto;
  height: 290px;  
  object-fit: contain; 
  transition: transform 0.5s ease-in-out;
} */

.large-image img {
  width: auto;
  height: 300px; 
  object-fit: contain; 
  transition: transform 0.5s ease-in-out;
}

.left img, .right img {
  opacity: 0.8; 
}
.large-image img {
  opacity: 1;
}

p {
  margin-top: 10px; 
  font-size: 16px;
  color: #000;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .ro-system-container {
    margin-top: 30px; /* Reduce margin on smaller screens */
  }

  .title {
    font-size: 32px; /* Smaller title for mobile */
    margin-bottom: 15px; /* Adjust margin */
  }


  .small-image1 {
    width: 100px; /* Smaller image for mobile */
    height: 100px;
  }

  .small-image1 img {
    width: auto;
  }

  .large-image img {
    height: 200px; /* Adjust large image size for mobile */
  }

  p {
    font-size: 14px; /* Smaller paragraph text for mobile */
  }

  .small-image1, .large-image {
    text-align: center;
    margin: 0 auto; /* Center align content on smaller screens */
  }
  .slider-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; 
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .image-container {
    display: flex;
    flex-direction: column; 
  }
}
