.product-section {
  background-color: #4648B9;
  position: relative;
  margin-top: 71.5px;
}

.left-right-container {
  display: flex;
  margin-left:25%;
}

.left-image-container, .right-image-container {
  text-align: center;
  margin-right:20%;

}

.product-image {
  width: 280px;
}

.pcolor {
  color: white;
  font-size: 16px;
  margin-top: 20px;
}

.overlap-images {
  position: relative;
  margin-top: 80px;
}

.overlap-image {
  width: 200px;
  position: absolute;
  top: 0;
  left: 50px;
  z-index: 1;
}

.second-overlap-image {
  width: 100%;
  height: 60%;  
  position: absolute;
  top: 490px;
  z-index: 2;
}

.footer-overlap {
  margin-top: 150px;
}

.footer-image {
  width: 100%;
  height: 100%;
  color:purple;
}
.purple-filter {
  filter: sepia(1) saturate(10000%) hue-rotate(270deg);
}


/* Mobile Styles */
@media screen and (max-width: 768px) {
  /* Default styles for larger screens */
.product-section {
  background-color: #4648B9;
  position: relative;
  margin-top: 1px;
}

  .left-right-container {
    display: block;
    margin-left: 0;
    text-align: center;
  }

  .left-image-container, .right-image-container {
    margin-right: 0;
  }

  .product-image {
    width: 200px; /* Smaller image size for mobile */
  }

  
  .footer-image {
    width: 100%;
    height: 100%;
    color: purple;
  }
  
  .purple-filter {
    filter: sepia(1) saturate(10000%) hue-rotate(270deg);
  }
  

  .second-overlap-image {
    height: 100%; /* Adjusted height for mobile */
    top: 90%;
  }

  .footer-overlap {
    margin-top: 0px; /* Reduce footer margin */
  }
  .overlap-image {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  
  .second-overlap-image {
    width: 100%;
    height: 80%;
    position: absolute;
    z-index: 2;
  }
  .left-right-container {
    display: flex;
    margin-left: 25%;
  }
  
  .left-image-container, .right-image-container {
    text-align: center;
    margin-right: 20%;
  }
}

/* For even smaller devices, like phones */

/* Mobile Styles */
@media screen and (max-width: 768px) {
  /* Default styles for larger screens */
.product-section {
  background-color: #4648B9;
  position: relative;
  margin-top: 1px;
}

  .left-right-container {
    display: block;
    margin-left: 0;
    text-align: center;
  }

  .left-image-container, .right-image-container {
    margin-right: 0;
  }

  .product-image {
    width: 200px; /* Smaller image size for mobile */
  }

  
  .footer-image {
    width: 100%;
    height: 100%;
    color: purple;
  }
  
  .purple-filter {
    filter: sepia(1) saturate(10000%) hue-rotate(270deg);
  }
  

  .second-overlap-image {
    height: 100%; /* Adjusted height for mobile */
    top: 60%;
  }

  .footer-overlap {
    margin-top: 0px; /* Reduce footer margin */
  }
  .overlap-image {
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    z-index: 1;

  }
  
  .second-overlap-image {
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: 2;
  }
  .left-right-container {
    display: flex;
    margin-left: 25%;
  }
  
  .left-image-container, .right-image-container {
    text-align: center;
    margin-right: 20%;
  }
}

/* For even smaller devices, like phones */
/* @media screen and (max-width: 480px) {
  .product-image {
    width: 150px; 
  }

  .overlap-image {
    width: 120px; 
    left: 10px;
  }

  .second-overlap-image {
    height: 35%; 
    top: 350px;
  }

  .pcolor {
    font-size: 14px; 
  }
} */

