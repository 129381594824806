.image-gallery {
    text-align: center;
    position: relative;
    margin-top: 10px;
}

.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: 5px;
}

.product-headingslider {
    font-family: 'Roboto', sans-serif; 
    font-weight: bold;
    font-size: 35px; 
    text-align: center; 
    margin: 20px 0; 
    color:black;
}

.small-images-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 80%;
}

.small-image {
    width: 160px; 
    height: 160px; 
    border-radius: 50%; 
    overflow: hidden;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    /* Optional border for visibility */
    /* border: 2px solid grey; */
    font-size: 1.2em;
}

.small-image:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

.placeholder-icon {
    font-size: 24px;
    color: #ccc; /* Placeholder icon color */
}

/* Arrow Button Styling */
.arrow-button {
    background: none;
    border: none;
    font-size: 20px; /* Increase size for better visibility */
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.left-arrow {
    left: 5%; /* Adjust for responsiveness */
    color: black; 
    background-color: #4648B9;
    border-radius: 80%;
}

.right-arrow {
    right: 5%; /* Adjust for responsiveness */
    color: black;
    background-color: #4648B9;
    border-radius: 80%;

}

.arrow-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.gallery-heading {
    font-size: 30px;
    color: #333;
    text-align: left; 
    font-weight: bold;
}



.image-item p {
    font-family: 'Inter', sans-serif;
    font-size: 14px; 
    font-weight: 400;
    text-align: center;
    margin-top: 8px;
}
.imagesliderproduct {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: contain;
  }
@media (max-width: 768px) {

    .small-images-container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        height:1%;
    }
    .image-item p {
        font-size: 10px; 
        text-align: center;
    }
    .arrow-button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .left-arrow {
        color: black; 
        background-color: #4648B9;
        border-radius: 80%;
        margin-right:90%; 
        z-index:1;

    }

      
    .right-arrow {
        color: black;
        background-color: #4648B9;
        border-radius: 80%;
        /* margin-top:90px;
        margin-right:110px; */
    
    }
    
    .small-image:hover {
        transform: scale(1.1); /* Slight zoom effect on hover */
    }
   
    .image-gallery {
        text-align: center;
        position: relative;
    }
    
    .carousel-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height:1%;

    }
    .imagesliderproduct {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        object-fit: contain;
      }
    .product-heading {
        font-family: 'Roboto', sans-serif; 
        font-weight: bold;
        font-size: 35px; 
        text-align: center; 
    }
   
   
}







































/* .image-gallery {
    text-align: center;
    position: relative;
    margin-top: 10px;

}

.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-top: 5px;
}
.product-heading {
    font-family: 'Roboto', sans-serif; 
    font-weight: bold;
    font-size: 35px; 
    text-align: center; 
    margin: 20px 0; 
}

.small-images-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 90%;
    
}

.small-image {
    width: 160px; 
    height: 160px; 
    border-radius: 50%; 
    overflow: hidden;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    border: 2px solid grey; 
    font-size: 1.2em;
}





.small-image:hover {
    transform: scale(1.1); 
}



.placeholder-icon {
    font-size: 24px;
    color: #ccc; 
}

.arrow-button {
    background: none;
    border: none;
    font-size: 30px; 
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.left-arrow {
    left: 170px; 
    color: black; 
    background-color: blue;

}

.right-arrow {
    right: 170px;
    color: black;
    background-color: blue;

}

.arrow-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.gallery-heading {
    font-size: 30px;
    color: #333;
    text-align: left; 
    font-weight: bold;
}

.water-drop-icon {
    color: #00aaff; 
}
.image-item p {
    font-family: 'Inter', sans-serif;
    font-size: 14px; 
    font-weight: 400;
    text-align: center;
    margin-top: 8px;
    
  }
   */