.header1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    
  }


  .title {
    margin: 0;
  }
  
  .subtitle {
    margin: 0;
    color: black;
  }
  
  .astra {
    width: 30%;
    margin-right: 50px;
  }
  
  .image-overlap {
    position: relative;
    max-width: 100%;

  }
  
  .ionizer-image {
    width: 100%;
    height: auto;
  }
  
  .overlap {
    position: absolute;
    top: 89px; /* Adjust to overlap correctly */
    left: 0px; /* Adjust to position */
    z-index: 1;
  }
  
  .dispenser-section {
    display: flex;
    margin-top: -456px; 
    justify-content: space-between;
    
  }
  .arrow {
    margin-left: 8px; /* Space between the subtitle and the arrow */
    vertical-align: middle; /* Aligns the arrow vertically with the text */
  }
  
  .water {
    margin-left: 200px; /* Space between the image and text */
    max-width: 175px;
  }
  
  .text-container1 {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-size: 40px; 
    position: relative; 
    margin-top: 150px; 
    margin-right:200px;

  }
  .text-container {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-size: 40px; /* Adjust the title size as needed */
    position: relative; /* Allows for overlap */
    z-index: 2; /* Bring text above the images */
    margin-left:120px;

  }
  /* General mobile styles */
@media (max-width: 768px) {
  .header1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px; /* Reduce padding for mobile */
  }

  .title {
    margin: 0;
    font-size: 24px; /* Smaller font size for titles */
  }

  .subtitle {
    margin: 0;
    font-size: 16px; /* Smaller font size for subtitles */
  }

  .astra {
    width: 50%; /* Increase width for better fit on mobile */
    margin-right: 20px; /* Adjust margin for mobile */
  }

  .image-overlap {
    position: relative;
    max-width: 100%;
  }

  .ionizer-image {
    width: 100%;
    height: auto;
  }

  .overlap {
    position: absolute;
    top: 30px; /* Adjust for better overlap on mobile */
    left: 0;
    z-index: 1;
  }

  .dispenser-section {
    margin-top: -140px; /* Adjust margin */
    display: flex;
    justify-content: space-between;
  }
 
  .arrow {
    margin-left: 5px; /* Reduce margin for mobile */
    vertical-align: middle;
    color: black;
  }

  .water {
    margin-left: 60px; /* Remove left margin for mobile */
    max-width: 80px; /* Smaller image size for mobile */
    height: 130px;
  }

  .text-container1 {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-size: 15px; 
    position: relative; 
    margin-top: 40px; 
    margin-right:30px;

  }

  .text-container {
    display: flex;
    flex-direction: column;
    
    font-family: 'Roboto', sans-serif;
    font-size: 24px; /* Smaller title size for mobile */
    position: relative;
    z-index: 2;
    margin-left: 10px; /* Reduce margin for mobile */
    padding: 10px; /* Add padding for better mobile layout */
  }
}
