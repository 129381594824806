.why-buy-section {
    text-align: center;
    margin: 40px 0;
  }
  
  .why-buy-title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .cards-container1 {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap
  }
  .card1.active {
    transform: scale(1.1); /* Increase size slightly on click */
    transition: transform 0.2s ease-in-out; /* Smooth transition */
  }
  .card1 {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    width: 220px;
    box-shadow: 0 2px 4px black;
    transition: transform 0.2s;
    color: black;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    width: 22%; /* Adjust width as necessary */
    height: 190px;
    display: block;
    text-decoration: none;
    color: inherit; 
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .blue-icon {
    color: blue;
  }
  
  .warranty-icon, .exchange-icon, .emi-icon {
    color: #ff5722; /* Change as needed */
  }

  
  
  .card1:hover {
    transform: translateY(-10px); /* Moves the card up by 10px */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds shadow on hover */
  }
  