/* Banner container */
.banner-container {
    position: relative;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    background: radial-gradient(76.37% 51.34% at 72.48% 42.14%, #FFFFFF 0.02%, #779ECB 100%);
    overflow: hidden; /* Prevent scrollbars */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  /* Header Image (Main Banner) */
  .header-image {
    width: 100vw; /* Full screen width */
    height: 100vh; /* 70% of the screen height */
    position: relative;
    z-index: 1; /* Ensure it's behind the second image */

  }
  
  /* Image Positioned to Overlap Below (headerImageWith) */
  .header-image-with {
    width: 100vw; /* Full screen width */
    height: 45vh; /* 30% of the screen height */
    position: absolute;
    z-index: 3; /* Ensures it overlaps */
    object-fit: cover; /* Ensures the image scales properly */
  }
  
  /* Group47 Image on the left of the banner */
/* Group47 Image on the left of the banner */
.group-image {
    width: 40vw; /* 25% of the viewport width */
    height: 60%;
    position: absolute;
    top: 29%;
    left: 5%;
    z-index: 5; /* Higher than other elements */
    object-fit: cover; /* Ensures it scales well */
}
@media (max-width: 768px) {
  /* Adjust banner container height for smaller screens */
  .banner-container {
      height: 50vh; /* Slightly less height for mobile */
  }

  /* Adjust header image for mobile */
  .header-image {
      height: 50vh; /* Reduce height for mobile */
  }

  /* Adjust overlapping image for mobile */
  .header-image-with {
      height: 20vh; /* Reduce height for mobile */
  }

  /* Adjust Group47 image for mobile */
  .group-image {
      width: 60vw; /* Use more of the screen width */
      top: 30%; /* Adjust positioning */
      left: 10%; /* Centered on mobile */
      height: 30vh; /* Reduce height for mobile */

  }
}

