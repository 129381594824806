/* General reset for margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Ensure the body does not scroll horizontally */
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
  
  /* Set a max width for containers to prevent horizontal scrolling */
  .container {
    max-width: 100vw; /* Set maximum width to the viewport width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    margin: 0 auto; /* Center align the container */
  }
  
  /* Optional: make sure the images in ImageGallery or other sections don't overflow */
  img {
    max-width: 100%;
    height: auto;
  }
  