/* ContactUs.css */

/* Base styles for the form */
.contact-us-container {
    background: #4648B9;
    padding: 20px;
  }
  
  .contact-us-title {
    font-family: 'Amiko', sans-serif;
    font-size: 20px;
    font-weight: 10;
    line-height: 50px;
    text-align: center;
    margin: 0;
    color: #fff; /* Adjust color as needed */
  }
  
  .contact-us-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Styles for inputs and textarea */
  .contact-us-input1 {
    margin: 10px;
    padding: 10px;
    width: 400px; /* Default width for larger screens */
  }
  .contact-us-input2 {
    margin: 10px;
    padding: 10px;
    width: 400px; /* Default width for larger screens */
  }
  
  
  .contact-us-textarea {
    margin: 10px;
    padding: 10px;
    width: 790px; /* Default width for larger screens */
    height: 100px;
  }
  
  .contact-us-button {
    margin: 10px;
    padding: 10px 20px;
    background: #000;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  
  @media (max-width: 768px) {
    
    .contact-us-input1 {
        margin-left: 237px;
        padding: 10px;
        width: 50%; /* Default width for larger screens */
      }
      .contact-us-input2 {
        margin-right: 237px;
       
        width: 50%; /* Default width for larger screens */
      }
      
    .contact-us-textarea {
      width: 80%; /* Use a percentage width on mobile */
      max-width: 100%; /* Ensure it doesn't exceed container */
    }
    
    .contact-us-container {
      padding: 10px; /* Reduce padding for mobile */
    }
  }
  