.footer {
    background-color: #f8f9fa;
    padding: 20px;
    border-top: 1px solid #dee2e6;
  }
  
  .footer-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-left: 30px;

  }
  .footer-row1 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-right: 700px;
    margin-left: 30px;

  }
  .footer-section {
    flex: 1;
    margin: 10px;
    min-width: 150px; /* Ensure sections have a minimum width */
  }
  
  .footer-section h3 {
    font-weight: bold;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section li {
    margin: 5px 0;
  }
  
  .footer-contact {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-contact h3 {
    font-weight: bold;
  }
  
  hr {
    margin: 20px 0;
  }
  
  
  .social-media {
    margin-top: 10px;
  }
  
  .social-media a {
    margin-right: 15px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #dee2e6;
    padding-top: 10px;
  }
  
  .footer-links a {
    margin: 0 10px;
  }
  .footer a {
    text-decoration: none; /* Remove underline */
    color: black; /* Set text color to black */
  }
  
  .footer a:hover {
    text-decoration: underline; /* Optional: underline on hover */
    color: #555; /* Optional: change color on hover */
  }
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  
  .footer-left {
    flex: 1;
    text-align: left;
    color: black;
  }
  
  .footer-center {
    flex: 1;
    text-align: center;
  }
  
  .footer-center a {
    color: black;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-center a:hover {
    text-decoration: underline;
  }
  
  .footer-right {
    flex: 1;
    text-align: right;
  }
  
  .footer-right a {
    color: black;
    margin: 0 10px;
    font-size: 20px;
    text-decoration: none;
  }
  
  .footer-right a:hover {
    color: #555;
  }
  
  /* Optional: Additional hover or style customization */
  .footer-right a:hover i {
    transform: scale(1.1);
    transition: transform 0.2s;
  }
  