/* Default styling for desktop and larger screens */
h2, h1 {
  font-family: 'Amiko';
  color: white;
}
/* Styling for the outer container */
.outer-container {
  display: flex;
  background-color: black;
  height: 99vh;
  position: relative;
}

h2 {
  font-size: 100px;
}

h1 {
  font-size: 150px;
  text-align: center;
  margin-bottom: 400px;
}

.softner-image {
  position: absolute;
  bottom: 0;
}

.softner-small {
  width: 30%;
  left: 40%;
  z-index: 2;
}

.softner-medium {
  width: 27%;
  left: 60%;
  z-index: 1;
}

.softner-large {
  width: 30%;
  left: 85%;
  transform: translateX(-50%);
}

.overlap-image {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 60%;
  object-fit: cover;
  z-index: 3;
}

.filter-heading {
  font-size: 50px;
  text-align: center;
  margin-top: 650px;
  z-index: 5;
  position: absolute;
  left: 45%;
  text-decoration: underline;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  h2 {
    font-size: 40px;
  }

  .overlap-image {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    object-fit: cover;
    z-index: 3;
  }
  h1 {
    font-size: 50px;
    margin-bottom: 120px;
  }

  .softner-small {
    width: 40%;
    left: 35%;
    z-index: 2;

  }

  .softner-medium {
    width: 35%;
    left: 55%;
    z-index: 1;

  }

  .softner-large {
    width: 40%;
    left: 80%;
    transform: translateX(-50%);

  }
  

  .filter-heading {
    font-size: 30px;
    margin-top: 230px;
    left: 35%;
  }
  .outer-container {
    display: flex;
    background-color: black;
    height: 50vh;
    position: relative;
  }
  
}
