.water-purifiers-container {
    padding: 20px;
  }
  
  .trending-heading {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .cards-container2 {
    display: flex;
    flex-wrap: wrap;
  }
  .card2:hover {
    transform: translateY(-10px); /* Moves the card up by 10px */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds shadow on hover */
  }
  
  .card2 {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    width: calc(24% - 10px); /* Adjust width for 4 cards in a row */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    height: 500px;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .price {
    font-weight: bold;
    color: #d9534f; /* Example color for price */
  }
  
  .mrp {
    text-decoration: line-through;
    color: #999;
  }
  
  .emi {
    color: #5cb85c; /* Example color for EMI */
  }
  .view-more-container {
    margin: 20px 0;
  }
  
  .view-more-button {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    margin: 0 5px;
    background-color: #00008B;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1.2rem;
  
  }
  
  .view-more-button:hover {
    background-color: #0056b3;
  }
  